.btn-container[data-v-e20ddd36] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: absolute;
  top: 5px;
  right: 12px;
}
.btn-container[data-v-e20ddd36] .el-button:focus,
.btn-container[data-v-e20ddd36] .el-button:hover {
  color: #303133;
  border-color: #f6f7fa;
  background-color: #f6f7fa;
}
.videoTopBox[data-v-e20ddd36] {
  background-color: #303133;
  position: relative;
  height: calc(100vh - 46px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.timeBox[data-v-e20ddd36] {
  position: absolute;
  right: 3px;
  bottom: 0;
  color: #ffffff;
  font-size: 20px;
}
.videoBtn[data-v-e20ddd36] {
  position: absolute;
  bottom: 3px;
  left: 3px;
}
.videoBox[data-v-e20ddd36] {
  padding: 10px;
  height: calc(100vh - 106px);
  background: #fff;
  overflow-y: auto;
  width: 280px;
}
.videoLittleBox[data-v-e20ddd36] {
  width: calc(100% - 20px);
  height: 100px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  margin-bottom: 10px;
}
.littleVideo[data-v-e20ddd36] {
  width: 100%;
  height: calc(100% - 27px);
}
.videoTitle[data-v-e20ddd36] {
  width: 90%;
  text-align: center;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileItem[data-v-e20ddd36] {
  text-decoration: underline;
  color: #333333;
  cursor: pointer;
  line-height: 30px;
  font-size: 14px;
}
.active[data-v-e20ddd36] {
  color: #409eff;
}
[data-v-e20ddd36] .videoBtn i {
  font-size: 22px;
}
.right-container[data-v-e20ddd36] {
  padding-left: 10px;
  position: relative;
}
.rightBtn[data-v-e20ddd36] {
  width: 48px;
  height: 96px;
  position: absolute;
  left: -20px;
  top: calc(50% - 48px);
  z-index: 99;
}
[data-v-e20ddd36] .el-tabs__header {
  margin: 0;
  padding: 0 12px;
}
[data-v-e20ddd36] .el-tabs__item {
  color: #d2d3d6;
}
[data-v-e20ddd36] .el-tabs__item.is-active {
  color: #303133;
}
[data-v-e20ddd36] .el-tabs__active-bar {
  background-color: #303133;
}
[data-v-e20ddd36] .el-tabs__item:hover {
  color: #303133;
}